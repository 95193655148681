<template>
  <div class="signout">
    <h3 class="mt-4 mb-4">Sign Out</h3>
    <b-alert dismissible fade :show="show_message">
      {{ message }}
      <b-link to="signin">Sign In Again</b-link>
    </b-alert>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: "SignOut",
  created: function() {
    //localStorage.removeItem("persist:store")
    this.signOut()
  },
  data() {
    return {
      message: "signing out",
      show_message: false,
      signin_status: true
    }
  },
  methods: {
    async signOut() {
      const path = process.env.VUE_APP_API_URI + "/accounts/logout"
      this.show_message = false
      console.log("signing out")
      try {
        const res = await this.axios.get(path)
        console.log(res)
        if (res.status == 200) {
          //this.pws_user_id = res.data.pws_id;
          this.$store.commit("set_signed_in", false)
          this.message = "You have been signed out."
          this.show_message = true
          //this.$emit('updateUsername', false);
          this.signin_status = false
        } else {
          this.message = "Unable to sign out. Bad response from the server."
          console.log("failed")
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
